import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'Menu',
    isTitle: true
  },
  // {
  //     id: 118,
  //     label: 'Dashboard',
  //     icon: 'fa-home',
  //     link: '/claims/back_office_dashboard',
  // },
  // {
  //     id: 108,
  //     label: 'Pending Claims',
  //     icon: 'fa-hourglass-half',
  //     link: '/claims/claims-waiting-for-approval',
  // },
  // {
  //     id: 109,
  //     label: 'Approved Claims',
  //     icon: 'fa-check-circle',
  //     link: '/claims/approved-claims',
  // },
  {
    id: 110,
    label: 'Report',
    icon: 'fa-chart-bar',
    link: '/report/reports',
    subItems: [
      {
        id: 200,
        label: 'HB Registration ',
        link: '/report/hb',
        parentId: 113
      },
      //   {
      //     id: 205,
      //     label: 'HB Project',
      //     link: '/report/hbprojectdetails',
      //     parentId: 113
      //   },
      //   {
      //     id: 207,
      //     label: 'HB Profile',
      //     link: '/report/hbprofile',
      //     parentId: 113
      //   },
      {
        id: 201,
        label: 'FDR Registration ',
        link: '/report/fdr',
        parentId: 113
      },
      //   {
      //     id: 206,
      //     label: 'FDR',
      //     link: '/report/fdr-report',
      //     parentId: 113
      //   },
      {
        id: 202,
        label: 'Concerns Raised',
        link: '/report/concerns',
        parentId: 113
      },
          {
              id: 203,
              label: 'Ledger Concerns',
              link: '/report/ledger-concerns',
              parentId: 113
          },
          {
            id: 205,
            label: 'Payment Report',
            link: '/report/payment-report',
            parentId: 113
        },
      //     {
      //       id: 204,
      //       label: 'Claim Concerns Raised',
      //       link: '/report/claim-concerns-raised',
      //       parentId: 113
      //   },
    ]
  },
  //  {
  //       id: 111,
  //       label: 'ADMIN',
  //       isTitle: true
  //   },
  // {
  //     id: 112,
  //     label: 'User Management',
  //     icon: 'fa-user',
  //     link: '/admin/users',
  // },
  // {
  //     id: 113,
  //     label: 'Back Officers',
  //     icon: 'fa-user-circle',
  //     subItems: [
  //         {
  //             id: 114,
  //             label: 'New Officer Registration',
  //             link: '/admin/back_officers/add',
  //             parentId: 113
  //         },
  //         {
  //             id: 115,
  //             label: 'Account Management',
  //             link: '/admin/back_officers/list',
  //             parentId: 113
  //         }
  //     ]
  // },
  // {
  //     id: 116,
  //     label: 'Form Management',
  //     icon: 'fa-file-alt',
  //     link: '/admin/forms',
  // },
  // {
  //     id: 117,
  //     label: 'Notifications',
  //     icon: 'far fa-bell',
  //     link: '/admin/notifications',
  // },
  // {
  //   id: 109,
  //   label: 'Old Ledger',
  //   icon: 'fa-file-signature',
  //   link: '/admin/old-ledger',
  // },

    {
    id: 118,
    label: 'Ledger',
    icon: 'fa-file-signature',
    link: '/admin/ledger',
    parentId: 113
  },

  // {
  //   id: 112,
  //   label: 'Offline Payments',
  //   icon: 'fa-money-check',
  //   link: '/admin/chequeList'
  // },
  {
    id: 114,
    label: 'Total Due Amount',
    icon: "fa-solid fa-money-bill",
    link: '/admin/tda'
  },
  {
    id:120,
    label:'TDS',
    icon:'fa-solid fa-file-invoice-dollar',
    link:'/admin/tds'
  },
  {
    id:127,
    label:'Update details',
    icon:'fa-sync',
    link:'/admin/customer-details'
  },
  //   {
  //     id: 122,
  //     label: 'Concern Notification',
  //     icon: 'far fa-bell',
  //     link: '/admin/concern-notifications',
  // },
  // {
  //     id: 118,
  //     label: 'Home Buyer',
  //     isTitle: true
  // },
  // {
  //     id: 119,
  //     label: 'Home Buyers',
  //     icon: 'fa-shopping-basket',
  //     subItems: [
  //         {
  //             id: 120,
  //             label: 'Update Project Details',
  //             link: '/home-buyer/home_buyer_add',
  //             parentId: 119
  //         },
  //         {
  //             id: 121,
  //             label: 'View Project Details',
  //             link: '/home-buyer/home_buyer_list',
  //             parentId: 119
  //         }
  //     ]
  // },

];
