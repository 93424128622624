import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userLocations: any;
  constructor(private http: HttpClient, private router: Router) {
    const cachedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    if (cachedUser) {
      const user = JSON.parse(cachedUser);
      this.accessToken = JSON.parse(localStorage.getItem('token'));
      this.refreshToken = JSON.parse(localStorage.getItem('token'));
      this.userB.next(user);
    }
  }

  private userB = new BehaviorSubject<User>(null);
  public user$ = this.userB.asObservable();

  accessToken: string;
  refreshToken: string;
  userName: any;

  generateOtp(mobileNumber: object) {
    return this.http.post(environment.userApi + `/api/user/public/otp/generate`, mobileNumber);
  }

  verifyOtp(otp: object) {
    return this.http.post(environment.userApi + `/api/user/public/otp/verify`, otp);
  }

  register(userDetails, capcha) {
    return this.http.post(environment.userApi + `/api/user/public/signup?g-recaptcha-response=${capcha}`, userDetails);
  }
  myProfile() {
    return this.http.get(environment.userApi + `/api/user/user`);
  }
  getClaimedUsers() {
    return this.http.get(environment.userApi + `/api/user/admin/users`);
  }
  getHbProjectDetails() {
    return this.http.get(environment.userApi + `/api/user/admin/hbProjectDetails`);
  }
  projectStatusUpdate(custCode,status) {
    return this.http.put(environment.userApi +`/api/user/admin/projectStatus/update/${custCode}/${status}`, {});
  }
  getS3Images(uniqueIdentifier) {
    return this.http.get(environment.userApi + `/api/user/files/${uniqueIdentifier}`, {
      responseType: 'blob',
    });
  }


  passwordRestMail(email) {
    return this.http.post(environment.userApi + `/api/user/public/password/link`, email);
  }

  resetPassword(newPassword, resetToken) {
    return this.http.post(environment.userApi + `/api/user/public/password/reset`, {
      password: newPassword,
      token: resetToken,
    });
  }
  changePassword(password, oldPassword) {
    return this.http.post(environment.userApi + `/api/user/password/changePassword`, {
      password: password,
      oldPassword: oldPassword,
    });
  }
  login(loginDetails) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    };
    return this.http.post<any>(environment.userApi + `/api/user/public/user/login`, loginDetails.toString(), options);
  }

  refreshSession() {
    return new Promise<boolean>((resolve, reject) => {
      const options = {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      };
      const body = new URLSearchParams();
      if (!this.getRefreshToken()) {
        return resolve(true);
      }
      body.set('refresh_token', this.getRefreshToken());
      body.set('grant_type', 'refresh_token');
      this.http.post<any>(environment.userApi + `/api/user/public/user/login`, body.toString(), options).subscribe(
        (newTokens) => {
          const user = { ...this.getUser(), access_token: newTokens.access_token, refresh_token: newTokens.refresh_token };
          this.userName = newTokens?.userName;
          console.log(this.userName);
          this.storeUserInfo(user);
          return resolve(true);
        },
        (err) => {
          this.logout();
          return resolve(true);
        },
      );
    });
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.userB.next(null);
    this.accessToken = null;
    this.refreshToken = null;
  }

  getAccessToken() {
    return localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token') || sessionStorage.getItem('refresh_token');
  }

  getUser() {
    const cachedUser = localStorage.getItem('user');
    return JSON.parse(cachedUser);
  }

  storeUserInfo(userData, rememberMeChecked?) {
    this.userB.next(userData);
    rememberMeChecked = localStorage.getItem('user') || rememberMeChecked;
    if (userData) {
      if (rememberMeChecked) {
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('access_token', userData.access_token);
        localStorage.setItem('refresh_token', userData.refresh_token);
        localStorage.setItem('user_name',userData?.userName);
      } else {
        sessionStorage.setItem('user', JSON.stringify(userData));
        sessionStorage.setItem('access_token', userData.access_token);
        sessionStorage.setItem('refresh_token', userData.refresh_token);
        sessionStorage.setItem('user_name',userData?.userName);
      }
    }
  }
  activateOrDeactivateUser(id, status) {
    return this.http.put(environment.userApi + `/api/user/admin/user/${id}/changeStatus`, { status });
  }

  sendNotification(notificationParams) {
    return this.http.post(environment.userApi + `/api/user/notification/send`, notificationParams);
  }

  sendConcernNotification(concernParams) {
    return this.http.post(environment.userApi + `/api/user/notification/raiseConcern/send`, concernParams);
  }

  getNotificationHistory(page) {
    return this.http.get(environment.userApi + `/api/user/notification/history?pageNo=${page.pageNumber}&pageSize=${page.size}`);
  }
  ledger(custCode,bodyparams,location) {
    return this.http.post(environment.claimsApi + `/api/cpm/ledger/${custCode}/${location}`, bodyparams);
  }
  posessionDate(custCode,location){
    return this.http.get(environment.claimsApi + `/api/cpm/ledger/Possession/${custCode}/${location}`,{});
  }
  locationData(){
    return this.http.get(environment.claimsApi + `/api/cpm/ledger/location`);
  }
 getAdvance(custId,location,user) : Observable<any>{
    return this.http.post<any>(environment.claimsApi + `/api/payment/payment/advancePaymentSettlement?custCode=${custId}&location=${location}&adminuser=${user}`,{})
  }
  getBillDetails(custId,location){
    return this.http.post(environment.claimsApi +`/api/cpm/paymentschedule/billdetails/${custId}/${location}`,custId,location);
  }

  invoiceGenerate(custCode, billNos, location) {
    return this.http.post(environment.claimsApi + `/api/cpm/paymentschedule/invoice/${custCode}/${location}`, billNos ,{ responseType: 'text' })
  }
  getdemandLetter(custId, billNo, location, pendingAmt, interestOnPendingAmt, type, taxOnInterest, taxOnPendingAmt) {
    return this.http.post(environment.claimsApi + `/api/cpm/letter/demandLetter/${custId}/${billNo}/${location}/${type}?pendingAmt=${pendingAmt}&interestOnPendingAmt=${interestOnPendingAmt}&taxOnInterest=${taxOnInterest}&previousInstTax=${taxOnPendingAmt}`, {});
  }
  // getTotalRefund(custId,location){
  //   return this.http.post(environment.claimsApi+`/api/cpm/paymentschedule/viewRefund/${custId}/${location}`,{})
  // }
  getRefund(custId,location){
    return this.http.post(environment.claimsApi+`/api/cpm/paymentschedule/viewRefund/${custId}/${location}`,{})
  }

  public async downloadResource(id: string): Promise<Blob> {
    const file = await this.http
      .get<Blob>(environment.userApi + '/api/user/file/' + id, { responseType: 'blob' as 'json' })
      .toPromise();
    return file;
  }

  public async downloadFile(id): Promise<string> {
    const blob = await this.downloadResource(id);
    const url = window.URL.createObjectURL(blob);
    return url;
  }
  oldLedger(custCode) {
    return this.http.get(environment.claimsApi + `/api/cpm/ledger/downloadPdf/${custCode}`, { responseType: 'text' });
  }

  raiseConcernLedger(concern: object) {
    return this.http.post(environment.userApi + `/api/user/user/raise/ledgerConcern`, concern);
  }

  fileUpload(file) {
    return this.http.post(environment.userApi + '/api/user/file/upload', file);
  }

  getOutstandingAmtDetails(custId,location){
    return this.http.post(environment.claimsApi +`/api/cpm/paymentschedule/billoutstandingdetails/${custId}/${location}`,custId,location);
  }
  getTdsDetails(locations){
    return this.http.post(environment.claimsApi + `/api/cpm/paymentschedule/allTdsDetails`,locations)
  }
  saveTdsPayment(response: object) {
    return this.http.post(environment.claimsApi + `/api/cpm/paymentschedule/saveTdsPayment`, response);
  }
  getTdsReceipts(custCode,location){
    return this.http.post(environment.claimsApi +`/api/cpm/paymentschedule/tdsReceipt/${custCode}/${location}`,{});
  }
  updateTdsPayment(custCode,location,cheqDate,amount,tdsId,documentId,paymentMode ){
    return this.http.post(environment.claimsApi +`/api/cpm/paymentschedule/tdsPayment/${custCode}/${location}`,{cheqDate,amount,tdsId,documentId,paymentMode});
  }

  getUserLocations(userName) {
    return this.http.post(environment.userApi + `/api/user/public/user/location`,userName);
  }

  getUserName() {
    return this.userName;
  }

  setUserName(username){
    this.userName = username;
  }
  settleTdsPayments(results){
    return this.http.post(environment.claimsApi + `/api/cpm/paymentschedule/settleTdsPayments`,results);
  }
  getTdsPayments(locations){
    return this.http.post(environment.claimsApi +`/api/cpm/paymentschedule/getTdsPaymentsByLocation`,locations)
  }
  getExportFile(startDate,endDate){
    return this.http.get(environment.userApi + `/api/user/admin/tableExcelExport/?startDate=${startDate}&endDate=${endDate}`,{ responseType: 'blob',
      params: {
        startDate: startDate,
        endDate: endDate
      }});
  }

  getAllDetails(startDate: string, endDate: string) {
    return this.http.get(environment.userApi + `/api/user/admin/paymentData/?startDate=${startDate}&endDate=${endDate}`, {})
  }
  getCustomerDetails(custCode,location){
    return this.http.get(environment.userApi + `/api/user/admin/customerBasicDetails/${custCode}/${location}`);
  }
  updateCustomerDetails(custCode, location, details: any) {
    return this.http.put(environment.userApi + `/api/user/admin/updateCustBasicDetails/${custCode}/${location}`, details);
  }
}
